/**
* Reset styles
*/

/* --- Page defaults --- */

html {
  box-sizing: border-box;
  font-size: 62.5%;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background-color: #fff;
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

html,
body,
#root {
  height: 100%;
}

/* --- Box model --- */

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

button {
  appearance: none;
  background: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  outline: none;
}

/* --- Typography --- */

h1 {
  font-size: 8.8rem;
  font-weight: 900;
  line-height: 1.1;
}

h2 {
  font-size: 4.8rem;
  font-weight: 900;
  line-height: 1.1;
}

h3 {
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 1.1;
}

h4 {
  font-size: inherit;
  letter-spacing: 0.2rem;
  line-height: inherit;
  text-transform: uppercase;
}

h1:not(:first-child),
h2:not(:first-child),
h3:not(:first-child),
h4:not(:first-child) {
  margin-top: 8rem;
}

h1:not(:last-child),
h2:not(:last-child),
h3:not(:last-child) {
  margin-bottom: 8rem;
}

h4:not(:last-child),
p:not(:last-child) {
  margin-bottom: 2rem;
}

p {
  font-size: inherit;
  line-height: inherit;
}

/* --- Anchor --- */

a:link {
  border-bottom: 0.1rem solid #000000;
  color: #000000;
  padding-bottom: 0.1rem;
  text-decoration: none;
}

a:active,
a:hover {
  border-color: #222222;
  color: #222222;
}

a:visited {
  border-color: #444444;
  color: #444444;
}
