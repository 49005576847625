@use "sass:color";
@use "sass:math";
/**
* Default styles
*/

/* --- Color --- */

$color--agave: rgb(115, 200, 210);
$color--cucumber: rgb(230, 245, 235);
$color--avocado: rgb(15, 100, 100);
$color--black: rgb(0, 0, 0);
$color--lime: rgb(110, 220, 130);
$color--grape: rgb(10, 40, 60);
$color--white: rgb(255, 255, 255);

.color--agave {
  color: $color--agave;
}

.color--avocado {
  color: $color--avocado;
}

.color--black {
  color: $color--black;
}

.color--lime {
  color: $color--lime;
}

.color--grape {
  color: $color--grape;
}

/* --- Typography --- */

$font__family: "IBM Plex Sans", "Verdana", sans-serif;
$font__weight--light: 300;
$font__weight--normal: 400;
$font__weight--medium: 500;
$font__weight--semibold: 600;
$font__weight--bold: 700;

/* --- Grid --- */

$grid--desktop: 1440px;
$grid--tablet: 1080px;
$grid--mobile: 720px;

/* --- Other --- */

$border__radius: 0.2rem;

/* --- Body --- */

body {
  background-color: $color--white;
  color: $color--black;
  font-family: $font__family;
  font-weight: $font__weight--normal;
  position: relative;
}

@media screen and (max-width: $grid--tablet) {
  body {
    font-size: 1.8rem;
  }
}

.root.hide-overflow {
  height: 100%;
  overflow: hidden;
}

h2,
h3,
h4 {
  font-weight: $font__weight--bold;
}

h4 {
  color: $color--avocado;
}

@media screen and (max-width: $grid--tablet) {
  h1 {
    font-size: 5.4rem;
  }

  h2 {
    font-size: 4.4rem;
  }

  h3 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: $grid--mobile) {
  h1 {
    font-size: 4.4rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 2rem;
  }

  h1:not(:last-child),
  h2:not(:last-child),
  h3:not(:last-child) {
    margin-bottom: 4rem;
  }
}

/* --- Anchor --- */

a:not([class]),
%anchor {
  border-bottom: 0.3rem solid $color--lime;
  color: $color--grape;
  cursor: pointer;
  font-weight: $font__weight--bold;
  padding-bottom: 0;
  text-decoration: none;
  
  &:hover {
    border-color: $color--lime;
    color: $color--lime;
  }
  
  &:active {
    border-color: $color--agave;
    color: $color--agave;
  }

  &:link .icon {
    margin-left: 0.5rem;
    transition: transform 600ms;
    will-change: transform;
  }

  &:hover .icon {
    transform: translate3d(0.5rem, 0, 0);
    transition: transform 200ms;
  }
}

/* --- Mixins --- */

@mixin aspect_ratio($width, $height) {
  height: 0;
  overflow: hidden;
  padding-bottom: math.percentage(($height / $width));
  width: 100%;
}

@mixin dots() {
  position: relative;

  &::before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0' y='0' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10' xml:space='preserve'%3E%3Crect width='10' fill='none' height='10'/%3E%3Crect x='0' y='0' fill='%236edc82' width='1' height='1'/%3E%3C/svg%3E");
    background-size: 1rem;
    content: "";
    height: 100%;
    opacity: 0.4;
    position: absolute;
    width: 100%;
    z-index: 4;
  }
}

@mixin grid($columns, $gap) {
  display: grid;
  grid-gap: $gap;
  grid-template-columns: repeat($columns, 1fr);
}

@mixin list() {
  ul {
    margin-left: 5rem;

    li {
      list-style: none;
      position: relative;

      &::before {
        color: $color--lime;
        content: "—";
        font-weight: bold;
        left: -3rem;
        position: absolute;
      }
    }

    @media screen and (max-width: $grid--mobile) {
      li::before {
        position: relative;
        left: 0;
        margin-right: 1rem;
      }
    }
  }

  @media screen and (max-width: $grid--mobile) {
    ul {
      margin-left: 0;
    }
  }
}

.button,
%button {
  appearance: none;
  background-color: $color--lime;
  border-radius: $border__radius;
  border: 0;
  color: $color--black;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: $font__weight--medium;
  line-height: 1;
  outline: 0;
  padding-bottom: 1.4rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 1rem;
  text-decoration: none;
  user-select: none;
  &:hover {
    background-color: color.adjust($color--lime, $lightness: -4%);
  }
  &:active {
    background-color: color.adjust($color--lime, $lightness: -8%);
  }
}

.icon,
%icon {
  font-size: inherit;
  margin-right: 0.5rem;
  position: relative;
  top: 0.25rem;
}

.icon-button,
%icon-button {
  color: $color--grape;
  cursor: pointer;
  margin-left: 1rem;
}

.container,
%container {
  margin-left: auto;
  margin-right: auto;
  max-width: $grid--desktop;
  position: relative;
}

.grid,
%grid {
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(12, 1fr);
}

/* --- Consent --- */

.consent {
  background-color: $color--avocado;
  bottom: 0;
  box-shadow: 0 0 4rem rgba($color--black, 0.5);
  color: $color--white;
  position: fixed;
  width: 100%;
  z-index: 9;
}

.consent__container {
  @extend %container;
  line-height: 1;
  padding: 2rem;
  text-align: center;
}

.consent__text {
  display: inline-block;
  margin-right: 2rem;
}

@media screen and (max-width: $grid--tablet) {
  .consent__text {
    display: block;
  }
}

.consent__text a {
  @extend %anchor;

  &:link,
  &:visited {
    border-color: rgba($color--white, 0.5);
    color: $color--white;
  }

  &:hover {
    border-color: $color--white;
  }
}

.consent__button {
  @extend %button;
}

@media screen and (max-width: $grid--tablet) {
  .consent__button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    margin-top: 2rem;
  }
}

/* --- Hero --- */

.hero {
  background-color: $color--grape;
  color: $color--white;
  height: auto;
  position: relative;
  z-index: 1;

  &[data-cover="true"] {
    height: 100vh;

    &::after {
      bottom: 16rem;
      color: $color--lime;
      content: "\eb5d";
      display: inline-block;
      font-family: "Nucleo";
      font-size: 4rem;
      height: 4rem;
      left: 50%;
      line-height: 1;
      margin-left: -2rem;
      position: absolute;
      width: 4rem;
      z-index: 9;
    }
  }
}

@media screen and (max-width: $grid--tablet) {
  .hero[data-cover="true"] {
    height: 75vh;

    &::after {
      bottom: 4rem;
    }
  }
}

.hero__background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
  
  &::after {
    background-image: linear-gradient(-180deg, rgba($color--grape, 1), rgba($color--grape, 0.5), rgba($color--grape, 1));
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
  }
}

.hero__background-video {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
  
  &::after {
    background-image: linear-gradient(-180deg, rgba($color--grape, 1), rgba($color--grape, 0.5), rgba($color--grape, 1));
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
  }
  
  video {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.hero__container {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 16rem;
  position: relative;
  z-index: 4;
}

@media screen and (max-width: $grid--tablet) {
  .hero__container {
    padding-bottom: 12rem;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 12rem;
  }
}

.hero__text {
  color: $color--white;
  flex-direction: column;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2,
  h3,
  h4 {
    color: inherit;
    margin-bottom: 2rem;
  }

  p {
    font-size: 2.8rem;
    line-height: 1;
  }
}

@media screen and (max-width: $grid--tablet) {
  .hero__text p {
    font-size: 2rem;
  }
}

/* --- Menu --- */

.menu {
  align-items: center;
  display: flex;
  height: 10.4rem;
  left: 0;
  padding: 4rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 8;
}

@media screen and (max-width: $grid--mobile) {
  .menu.is--active {
    background-color: $color--white;
    align-items: center;
    display: block;
    height: auto;
    min-height: 100%;
    z-index: 8;

    .menu__container {
      display: block;
    }

    .menu__logo path {
      fill: $color--avocado;
    }
  }
}

.menu__container {
  @extend %container;
  display: flex;
  flex: 1;
  padding-left: 8rem;
  padding-right: 8rem;
  position: unset;
}

@media screen and (max-width: $grid--tablet) {
  .menu__container {
    padding-left: 0;
    padding-right: 0;
  }
}

a.menu__logo {
  align-self: flex-start;
  border-bottom: 0;
  line-height: 1;
  padding-bottom: 0;
}

a.menu__logo:only-child {
  margin-left: auto;
  margin-right: auto;
}

.menu__logo svg {
  display: inline-block;
  height: 2.4rem;
  width: auto;

  path {
    fill: $color--white;
  }

  path:last-child {
    fill: $color--lime !important;
  }
}

@media screen and (max-width: $grid--mobile) {
  .menu.is--active .menu__logo {
    position: relative;
    top: -0.1rem;
  }
}

.menu__icon {
  background: none;
  border: 0;
  cursor: pointer;
  display: none;
  outline: 0;
}

@media screen and (max-width: $grid--mobile) {
  .menu__icon {
    display: inline-block;
    height: 2.4rem;
    position: absolute;
    right: 4rem;
    top: 4rem;
    width: 2.4rem;
  }
  
  .menu__icon span {
    background-color: $color--white;
    border-radius: 0.2rem;
    display: block;
    height: 0.4rem;
    position: relative;
    width: 100%;
  }
  
  .menu__icon span + span {
    margin-top: 0.6rem;
  }
  
  .menu.is--active .menu__icon span {
    background-color: $color--lime;
  }
  
  .menu.is--active .menu__icon span:nth-child(1) {
    top: 1rem;
    transform-origin: center;
    transform: rotate(45deg) scaleX(1.414);
  }
  
  .menu.is--active .menu__icon span:nth-child(2) {
    transform-origin: center;
    transform: rotate(-45deg) scaleX(1.414);
  }
    
  .menu.is--active .menu__icon span:nth-child(3) {
    opacity: 0;
  }
}

.menu__list {
  list-style: none;
  margin-left: auto;
}

@media screen and (max-width: $grid--mobile) {
  .menu__list {
    display: none;
  }
  
  .menu.is--active .menu__list {
    display: block;
    margin-left: 0;
    margin-top: 8rem;
    text-align: center;
  }
}

.menu__item {
  display: inline-block;
}

.menu__item + .menu__item {
  margin-left: 3rem;
}

@media screen and (max-width: $grid--mobile) {
  .menu__item {
    display: block;
  }
  
  .menu__item + .menu__item {
    margin-left: 0;
    margin-top: 4rem;
  }
}

.menu:not(.is--active) a:not([class]) {
  color: $color--white;

  &:hover {
    border-color: $color--lime;
    color: $color--lime;
  }
  
  &:active {
    border-color: $color--agave;
    color: $color--agave;
  }
}

/* --- Section --- */

.section {
  &:not(:only-of-type):not(:last-of-type) {
    border-bottom: 0.2rem solid $color--cucumber;
  }

  &[data-background-color] {
    border-bottom: 0;
  }

  &[data-background-color="avocado"] {
    background-color: $color--avocado;
    color: $color--lime;
  }

  &[data-background-color="grape"] {
    background-color: $color--grape;
    color: $color--lime;
  }

  &[data-background-color="lime"] {
    background-color: $color--lime;
    color: $color--grape;
  }
}

.section__container {
  @extend %container;
  align-items: center;
  display: flex;
  padding-bottom: 16rem;
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 16rem;
  position: relative;

  &[data-align="center"] {
    text-align: center;
  }
  
  &[data-align="left"] {
    text-align: left;
  }
  
  &[data-align="right"] {
    text-align: right;
  }

  &[data-contain="false"] {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }
}

@media screen and (max-width: $grid--tablet) {
  .section__container {
    flex-direction: column;
    padding: 8rem !important;
  }
}

@media screen and (max-width: $grid--mobile) {
  .section__container {
    padding: 4rem !important;
  }
}

.section__text {
  flex: 1;
}

@media screen and (max-width: $grid--tablet) {
  .section__text {
    order: 2;
  }
}

.section__text--center {
  text-align: center;
}

.section__text:only-child {
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: $grid--tablet) {
  .section__text:only-child {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    width: 100%;
  }
}

.section__container[data-image-position="left"] .section__text {
  margin-left: 12rem;
}

.section__container[data-image-position="right"] .section__text {
  margin-right: 12rem;
}

@media screen and (max-width: $grid--tablet) {
  .section__container[data-image-position="left"] .section__text,
  .section__container[data-image-position="right"] .section__text {
    margin-left: 0;
    margin-right: 0;
    margin-top: 8rem;
  }
}

@media screen and (max-width: $grid--mobile) {
  .section__container[data-image-position="left"] .section__text,
  .section__container[data-image-position="right"] .section__text {
    margin-top: 4rem;
  }
}

.section__image {
  background-color: $color--black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 0 5rem rgba(0, 0, 0, 0.2);
  flex: 1;
  height: 48rem;
  position: relative;
  transform-origin: center;
  transition: transform 300ms ease-in-out, box-shadow 300ms ease-in-out;
  width: 100%;
  z-index: 1;

  &::after {
    background-image: linear-gradient(-180deg, rgba($color--grape, 1), rgba($color--grape, 0.5), rgba($color--grape, 1));
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.25;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}

@media screen and (max-width: $grid--tablet) {
  .section__image {
    @include aspect_ratio(16, 9);
    box-shadow: none;
    flex: 1;
    order: 1;
  }
}

/* --- Portfolio --- */

.portfolio__list {
  @include grid(3, 0);
}

@media screen and (max-width: $grid--tablet) {
  .portfolio__list {
    @include grid(2, 0);
  }
}

@media screen and (max-width: $grid--mobile) {
  .portfolio__list {
    @include grid(1, 0);
  }
}

.portfolio__item {
  border: 0.2rem solid $color--cucumber;
  box-sizing: border-box;
  font-size: 3rem;
  font-weight: $font__weight--bold;
  margin-left: -0.2rem;
  margin-top: -0.2rem;
  text-align: center;
}

@media screen and (max-width: $grid--tablet) {
  .portfolio__item {
    font-size: 2rem;
  }
}

.portfolio__link {
  display: block;
  transition: background-color 100ms ease-in-out, color 100ms ease-in-out;

  &:link {
    color: $color--avocado;
    border: 0;
    padding: 12rem 4rem;
  }

  &:hover {
    background-color: $color--cucumber;
    color: $color--avocado;
    opacity: 1;
  }
}

@media screen and (max-width: $grid--tablet) {
  .portfolio__link:link {
    padding: 2rem;
  }
}

/* --- Logo --- */

.logo__list {
  @include grid(2, 0);
  border: 0.1rem solid $color--cucumber;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

@media screen and (max-width: $grid--tablet) {
  .logo__list {
    @include grid(2, 0);
  }
}

@media screen and (max-width: $grid--mobile) {
  .logo__list {
    @include grid(1, 0);
  }
}

.logo__item {
  border: 0.1rem solid $color--cucumber;
  font-size: 6rem;
  font-weight: $font__weight--bold;
  padding: 4rem;
  text-align: center;
}

@media screen and (max-width: $grid--tablet) {
  .logo__item {
    font-size: 3rem;
    padding: 2rem;
  }
}

/* --- Typefaces --- */

.typeface__list {
  @include grid(2, 0);
  border: 0.1rem solid $color--cucumber;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

@media screen and (max-width: $grid--tablet) {
  .typeface__list {
    @include grid(1, 0);
  }
}

.typeface__item {
  border: 0.1rem solid $color--cucumber;
  color: $color--avocado;
  font-size: 2rem;
  font-weight: $font__weight--bold;
  padding: 4rem 2rem;
  text-align: center;
}

@media screen and (max-width: $grid--tablet) {
  .typeface__item {
    padding: 2rem 0;
  }
}

/* --- Colors --- */

.color__list {
  @include grid(3, 0);
}

@media screen and (max-width: $grid--tablet) {
  .color__list {
    @include grid(2, 0);
  }
}

@media screen and (max-width: $grid--mobile) {
  .color__list {
    @include grid(1, 0);
  }
}

.color__item {
  align-items: center;
  display: block;
  font-size: 1.6rem;
  height: 20rem;
  line-height: 1.25;
  padding: 4rem;
  text-align: left;
}

@media screen and (max-width: $grid--tablet) {
  .color__item {
    height: 15rem;
    padding: 2rem;
  }
}

.color__name {
  font-weight: $font__weight--bold;
}

/* --- Collapsable --- */

.collapsable {
  &+& {
    margin-top: 2rem;
  }

  &[data-targeted="true"] .collapsable__body {
    box-shadow: 0 0 10rem $color--agave;
    display: block;
  }
}

.collapsable__title {
  background-color: $color--agave;
  cursor: pointer;
  display: block;
  font-weight: $font__weight--bold;
  padding: 4rem;
  user-select: none;

  .icon {
    float: right;
    position: relative;
    top: 0.5rem;
  }
}

@media screen and (max-width: $grid--tablet) {
  .collapsable__title {
    padding: 2rem;
  }
}

.collapsable__body {
  background-color: $color--cucumber;
  display: none;
  padding: 4rem;

  &[data-collapsed="false"] {
    display: block;
  }

  h4:not(:first-child) {
    margin-top: 4rem;
  }

  @include list();
}

/* --- Footer --- */

.footer {
  background-color: $color--avocado;
  color: $color--white;
}

.footer__logo {
  display: inline-block;
  height: 4rem;
  margin-left: 1rem;
}

@media screen and (max-width: $grid--tablet) {
  .footer__logo {
    height: 3rem;
  }
}

.footer__logo svg {
  height: 100%;
}

.footer__logo path {
  fill: $color--white;
  &:last-child {
    fill: $color--lime;
  }
}

.footer__address {
  font-size: 1.6rem;
  font-style: normal;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.footer__list {
  list-style: none;
}

.footer__item {
  display: inline-block;
  font-size: 1.6rem;

  &+& {
    margin-left: 2rem;
  }
}

a.footer__link {
  @extend %anchor;
  border-color: $color--lime;
  color: $color--white;

  &:hover {
    border-color: $color--lime;
    color: $color--lime;
  }

  &:active,
  &:focus {
    border-color: $color--agave;
    color: $color--agave;
  }
}
