@use "sass:string";
/**
* Icons
*/

/* --- Nucleo --- */

$icon-font-path: "../fonts" !default;
$icon-background-color: #eee !default;
$icon-li-margin-right: 0.4rem !default;

@mixin icon-rotate($degrees, $rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation});
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin icon-flip($horiz, $vert, $rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation});
  -webkit-transform: scale($horiz, $vert);
  -moz-transform: scale($horiz, $vert);
  -ms-transform: scale($horiz, $vert);
  -o-transform: scale($horiz, $vert);
  transform: scale($horiz, $vert);
}

@font-face {
  font-family: 'Nucleo';
  src: url('#{$icon-font-path}/Nucleo.eot');
  src: url('#{$icon-font-path}/Nucleo.eot') format('embedded-opentype'),
    url('#{$icon-font-path}/Nucleo.woff2') format('woff2'),
    url('#{$icon-font-path}/Nucleo.woff') format('woff'),
    url('#{$icon-font-path}/Nucleo.ttf') format('truetype'),
    url('#{$icon-font-path}/Nucleo.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/

.icon {
  display: inline-block;
  font: normal normal normal 1.6rem/1 'Nucleo';
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  change icon size
-------------------------*/

/* relative units */

.icon-sm {
  font-size: 1rem;
}

.icon-lg {
  font-size: 2.2rem;
}

/* absolute units */

.icon-16 {
  font-size: 16px;
}

.icon-32 {
  font-size: 32px;
}

/*----------------------------------
  add a square/circle background
-----------------------------------*/

.icon-bg-square,
.icon-bg-circle {
  padding: 0.35em;
  background-color: $icon-background-color;
}

.icon-bg-circle {
  border-radius: 50%;
}

/*------------------------------------
  use icons as list item markers
-------------------------------------*/

.icon-ul {
  padding-left: 0;
  list-style-type: none;

  > li {
    display: flex;
    align-items: flex-start;
    line-height: 1.4;
  }

  > li > .icon {
    margin-right: $icon-li-margin-right;
    line-height: inherit;
  }
}

/*------------------------
  spinning icons
-------------------------*/

.icon-is-spinning {
  -webkit-animation: icon-spin 2s infinite linear;
  -moz-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*------------------------
  rotated/flipped icons
-------------------------*/

.icon-rotate-90  {
	@include icon-rotate(90deg, 1);
}

.icon-rotate-180 {
	@include icon-rotate(180deg, 2);
}

.icon-rotate-270 {
	@include icon-rotate(270deg, 3);
}

.icon-flip-y {
	@include icon-flip(-1, 1, 0);
}

.icon-flip-x {
	@include icon-flip(1, -1, 2);
}

/*------------------------
	icons
-------------------------*/

@function unicode($str) {
  @return string.unquote("\"\\#{$str}\"");
}

$icon-norway: unicode(ea02);
$icon-usa: unicode(ea03);
$icon-denmark: unicode(ea04);
$icon-sweeden: unicode(ea05);
$icon-active-38: unicode(ea06);
$icon-active-40: unicode(ea07);
$icon-c-warning: unicode(ea08);
$icon-c-question: unicode(ea09);
$icon-c-info: unicode(ea0a);
$icon-t-warning: unicode(ea0b);
$icon-align-center: unicode(ea0c);
$icon-align-justify: unicode(ea0d);
$icon-align-left-2: unicode(ea0e);
$icon-align-right-2: unicode(ea0f);
$icon-s-pulse: unicode(ea10);
$icon-angle: unicode(ea11);
$icon-apple: unicode(ea12);
$icon-archive-drawer: unicode(ea13);
$icon-atom: unicode(ea14);
$icon-attachment: unicode(ea15);
$icon-attach: unicode(ea16);
$icon-microphone-2: unicode(ea17);
$icon-award-49: unicode(ea18);
$icon-medal: unicode(ea19);
$icon-award: unicode(ea1a);
$icon-b-meeting: unicode(ea1b);
$icon-badge-13: unicode(ea1c);
$icon-badge: unicode(ea1d);
$icon-shopping-bag: unicode(ea1e);
$icon-bag-20: unicode(ea1f);
$icon-bag: unicode(ea20);
$icon-ban: unicode(ea21);
$icon-temple: unicode(ea22);
$icon-barcode-qr: unicode(ea23);
$icon-bluetooth: unicode(ea24);
$icon-books: unicode(ea25);
$icon-box-2: unicode(ea26);
$icon-box-ribbon: unicode(ea27);
$icon-box: unicode(ea28);
$icon-brain: unicode(ea29);
$icon-sun: unicode(ea2a);
$icon-bug: unicode(ea2b);
$icon-office: unicode(ea2c);
$icon-bulb-63: unicode(ea2d);
$icon-bulb-saver: unicode(ea2e);
$icon-cable: unicode(ea2f);
$icon-camera: unicode(ea30);
$icon-canvas: unicode(ea31);
$icon-car-front: unicode(ea32);
$icon-chart-bar-33: unicode(ea33);
$icon-b-chat: unicode(ea34);
$icon-check: unicode(ea35);
$icon-d-check: unicode(ea36);
$icon-cheque: unicode(ea37);
$icon-circle-10: unicode(ea38);
$icon-clone: unicode(ea39);
$icon-cloud-forecast: unicode(ea3a);
$icon-cloud-download-93: unicode(ea3b);
$icon-migration: unicode(ea3c);
$icon-code: unicode(ea3d);
$icon-coins: unicode(ea3e);
$icon-command: unicode(ea3f);
$icon-compare: unicode(ea40);
$icon-compass-06: unicode(ea41);
$icon-computer: unicode(ea42);
$icon-cone: unicode(ea43);
$icon-connection: unicode(ea44);
$icon-conversion: unicode(ea45);
$icon-copy: unicode(ea46);
$icon-countdown-2: unicode(ea47);
$icon-coupon: unicode(ea48);
$icon-credit-card-in: unicode(ea49);
$icon-credit-card: unicode(ea4a);
$icon-crop: unicode(ea4b);
$icon-crosshair: unicode(ea4c);
$icon-currency-exchange: unicode(ea4d);
$icon-cursor-pointer: unicode(ea4e);
$icon-respond-arrow: unicode(ea4f);
$icon-reply-arrow: unicode(ea50);
$icon-customer-support: unicode(ea51);
$icon-f-dashboard: unicode(ea52);
$icon-database: unicode(ea53);
$icon-delete-x: unicode(ea54);
$icon-delete-key: unicode(ea55);
$icon-delivery-3: unicode(ea56);
$icon-delivery-fast: unicode(ea57);
$icon-delivery: unicode(ea58);
$icon-design: unicode(ea59);
$icon-wheelchair: unicode(ea5a);
$icon-discount-2: unicode(ea5b);
$icon-dna-27: unicode(ea5c);
$icon-doctor: unicode(ea5d);
$icon-i-edit: unicode(ea5e);
$icon-edit: unicode(ea5f);
$icon-eraser-33: unicode(ea60);
$icon-exchange: unicode(ea61);
$icon-explore-2: unicode(ea62);
$icon-eye: unicode(ea63);
$icon-b-eye: unicode(ea64);
$icon-heart: unicode(ea65);
$icon-star: unicode(ea66);
$icon-file-download-89: unicode(ea67);
$icon-file-upload-88: unicode(ea68);
$icon-filter-check: unicode(ea69);
$icon-filter-remove: unicode(ea6a);
$icon-filter: unicode(ea6b);
$icon-flag: unicode(ea6c);
$icon-folder-19: unicode(ea6d);
$icon-gift: unicode(ea6e);
$icon-archery-target: unicode(ea6f);
$icon-gps: unicode(ea70);
$icon-grammar-check: unicode(ea71);
$icon-grid-interface: unicode(ea72);
$icon-hand-card: unicode(ea73);
$icon-handout: unicode(ea74);
$icon-hat-3: unicode(ea75);
$icon-headphones-mic: unicode(ea76);
$icon-headphones: unicode(ea77);
$icon-favorite: unicode(ea78);
$icon-heartbeat: unicode(ea79);
$icon-hierarchy-53: unicode(ea7a);
$icon-home: unicode(ea7b);
$icon-hospital-32: unicode(ea7c);
$icon-hotel: unicode(ea7d);
$icon-infinite: unicode(ea7e);
$icon-input: unicode(ea7f);
$icon-key: unicode(ea80);
$icon-keyboard-hide: unicode(ea81);
$icon-keyboard: unicode(ea82);
$icon-lab: unicode(ea83);
$icon-language: unicode(ea84);
$icon-launch: unicode(ea85);
$icon-law: unicode(ea86);
$icon-layers-2: unicode(ea87);
$icon-stack: unicode(ea88);
$icon-leaf: unicode(ea89);
$icon-light-traffic: unicode(ea8a);
$icon-thumb-down: unicode(ea8b);
$icon-thumb-up: unicode(ea8c);
$icon-url: unicode(ea8d);
$icon-unlink: unicode(ea8e);
$icon-list-bullet: unicode(ea8f);
$icon-list-numbers: unicode(ea90);
$icon-unlocked: unicode(ea91);
$icon-lock: unicode(ea92);
$icon-log-in: unicode(ea93);
$icon-log-out: unicode(ea94);
$icon-loop-82: unicode(ea95);
$icon-loop-83: unicode(ea96);
$icon-magnet: unicode(ea97);
$icon-map: unicode(ea98);
$icon-marker: unicode(ea99);
$icon-math: unicode(ea9a);
$icon-measure-02: unicode(ea9b);
$icon-measure-17: unicode(ea9c);
$icon-measure-big: unicode(ea9d);
$icon-meeting: unicode(ea9e);
$icon-menu-8: unicode(ea9f);
$icon-menu-7: unicode(eaa0);
$icon-menu-6: unicode(eaa1);
$icon-menu-5: unicode(eaa2);
$icon-menu-4: unicode(eaa3);
$icon-menu-3: unicode(eaa4);
$icon-menu: unicode(eaa5);
$icon-merge-2: unicode(eaa6);
$icon-merge: unicode(eaa7);
$icon-metrics: unicode(eaa8);
$icon-mic-2: unicode(eaa9);
$icon-microscope: unicode(eaaa);
$icon-down-arrow: unicode(eaab);
$icon-left-arrow: unicode(eaac);
$icon-right-arrow: unicode(eaad);
$icon-up-arrow: unicode(eaae);
$icon-phone-charging: unicode(eaaf);
$icon-money-coins: unicode(eab0);
$icon-money-growth: unicode(eab1);
$icon-money-time: unicode(eab2);
$icon-pc-mouse: unicode(eab3);
$icon-move-05: unicode(eab4);
$icon-move-92: unicode(eab5);
$icon-move-down: unicode(eab6);
$icon-move-left: unicode(eab7);
$icon-move-right: unicode(eab8);
$icon-move-up: unicode(eab9);
$icon-multiple-11: unicode(eaba);
$icon-multiple: unicode(eabb);
$icon-network: unicode(eabc);
$icon-newsletter: unicode(eabd);
$icon-night: unicode(eabe);
$icon-music: unicode(eabf);
$icon-notepad: unicode(eac0);
$icon-paint-16: unicode(eac1);
$icon-palette: unicode(eac2);
$icon-panel: unicode(eac3);
$icon-pantone: unicode(eac4);
$icon-paper-diploma: unicode(eac5);
$icon-paper: unicode(eac6);
$icon-paragraph: unicode(eac7);
$icon-parent: unicode(eac8);
$icon-patch-19: unicode(eac9);
$icon-patch-34: unicode(eaca);
$icon-patch: unicode(eacb);
$icon-path-exclude: unicode(eacc);
$icon-path-intersect: unicode(eacd);
$icon-path-minus: unicode(eace);
$icon-path-unite: unicode(eacf);
$icon-paw: unicode(ead0);
$icon-payment: unicode(ead1);
$icon-computer-monitor: unicode(ead2);
$icon-pen-tool: unicode(ead3);
$icon-pencil: unicode(ead4);
$icon-percentage-38: unicode(ead5);
$icon-phone: unicode(ead6);
$icon-phone-call-end: unicode(ead7);
$icon-phone-call: unicode(ead8);
$icon-mobile-phone: unicode(ead9);
$icon-pig: unicode(eada);
$icon-pill-container-44: unicode(eadb);
$icon-pen-23: unicode(eadc);
$icon-pen-01: unicode(eadd);
$icon-pin: unicode(eade);
$icon-plane-18: unicode(eadf);
$icon-plant-ground: unicode(eae0);
$icon-plant-vase: unicode(eae1);
$icon-play-movie: unicode(eae2);
$icon-media-player: unicode(eae3);
$icon-plug: unicode(eae4);
$icon-podium-trophy: unicode(eae5);
$icon-point-a: unicode(eae6);
$icon-point-b: unicode(eae7);
$icon-pos: unicode(eae8);
$icon-position-pin: unicode(eae9);
$icon-system-preferences: unicode(eaea);
$icon-setup-preferences: unicode(eaeb);
$icon-options: unicode(eaec);
$icon-preferences: unicode(eaed);
$icon-printer: unicode(eaee);
$icon-progress: unicode(eaef);
$icon-pulse-sleep: unicode(eaf0);
$icon-watch-heartbeat: unicode(eaf1);
$icon-pulse: unicode(eaf2);
$icon-puzzle-10: unicode(eaf3);
$icon-quite-happy: unicode(eaf4);
$icon-quote: unicode(eaf5);
$icon-radio: unicode(eaf6);
$icon-receipt-list-42: unicode(eaf7);
$icon-receipt-list-43: unicode(eaf8);
$icon-recycling: unicode(eaf9);
$icon-redo-26: unicode(eafa);
$icon-refresh: unicode(eafb);
$icon-reload: unicode(eafc);
$icon-replay: unicode(eafd);
$icon-restore: unicode(eafe);
$icon-road-2: unicode(eaff);
$icon-screen-rotation: unicode(eb00);
$icon-round-dollar: unicode(eb01);
$icon-route-alert: unicode(eb02);
$icon-route-close: unicode(eb03);
$icon-route-open: unicode(eb04);
$icon-ruler-pencil: unicode(eb05);
$icon-safe: unicode(eb06);
$icon-scale: unicode(eb07);
$icon-scan: unicode(eb08);
$icon-scissors-dashed: unicode(eb09);
$icon-scissors: unicode(eb0a);
$icon-search-content: unicode(eb0b);
$icon-search-file: unicode(eb0c);
$icon-security: unicode(eb0d);
$icon-segmentation: unicode(eb0e);
$icon-increase: unicode(eb0f);
$icon-enlarge: unicode(eb10);
$icon-send: unicode(eb11);
$icon-separate: unicode(eb12);
$icon-settings-46: unicode(eb13);
$icon-settings-99: unicode(eb14);
$icon-cogwheel: unicode(eb15);
$icon-settings-gear: unicode(eb16);
$icon-gear: unicode(eb17);
$icon-settings: unicode(eb18);
$icon-network-communication: unicode(eb19);
$icon-upload: unicode(eb1a);
$icon-social-sharing: unicode(eb1b);
$icon-sharing: unicode(eb1c);
$icon-shared: unicode(eb1d);
$icon-reply: unicode(eb1e);
$icon-shuffle-97: unicode(eb1f);
$icon-sign: unicode(eb20);
$icon-signature: unicode(eb21);
$icon-e-add: unicode(eb22);
$icon-e-delete: unicode(eb23);
$icon-pins: unicode(eb24);
$icon-e-remove: unicode(eb25);
$icon-single-05: unicode(eb26);
$icon-single-content-02: unicode(eb27);
$icon-single-content-03: unicode(eb28);
$icon-single-copies: unicode(eb29);
$icon-single-copy-04: unicode(eb2a);
$icon-single-copy-06: unicode(eb2b);
$icon-file-content: unicode(eb2c);
$icon-single-folded: unicode(eb2d);
$icon-file-paragraph: unicode(eb2e);
$icon-single-position: unicode(eb2f);
$icon-single: unicode(eb30);
$icon-slice: unicode(eb31);
$icon-slide-left: unicode(eb32);
$icon-slide-right: unicode(eb33);
$icon-i-add: unicode(eb34);
$icon-i-delete: unicode(eb35);
$icon-small-down: unicode(eb36);
$icon-small-left: unicode(eb37);
$icon-i-remove: unicode(eb38);
$icon-small-right: unicode(eb39);
$icon-small-triangle-down: unicode(eb3a);
$icon-small-triangle-left: unicode(eb3b);
$icon-small-triangle-right: unicode(eb3c);
$icon-small-triangle-up: unicode(eb3d);
$icon-small-up: unicode(eb3e);
$icon-snow: unicode(eb3f);
$icon-sound-wave: unicode(eb40);
$icon-loudspeaker: unicode(eb41);
$icon-split: unicode(eb42);
$icon-data-download: unicode(eb43);
$icon-square-pin: unicode(eb44);
$icon-s-remove: unicode(eb45);
$icon-frame-s-caret-right: unicode(eb46);
$icon-square-simple-down: unicode(eb47);
$icon-square-simple-left: unicode(eb48);
$icon-square-simple-right: unicode(eb49);
$icon-square-simple-up: unicode(eb4a);
$icon-data-upload: unicode(eb4b);
$icon-stamp: unicode(eb4c);
$icon-standing-man: unicode(eb4d);
$icon-standing-woman: unicode(eb4e);
$icon-steps: unicode(eb4f);
$icon-stock-2: unicode(eb50);
$icon-stock: unicode(eb51);
$icon-stretch: unicode(eb52);
$icon-food-supplement: unicode(eb53);
$icon-lifering: unicode(eb54);
$icon-support: unicode(eb55);
$icon-swap-horizontal: unicode(eb56);
$icon-swap-vertical: unicode(eb57);
$icon-sync-devices: unicode(eb58);
$icon-syringe: unicode(eb59);
$icon-system-update: unicode(eb5a);
$icon-tactic: unicode(eb5b);
$icon-tag: unicode(eb5c);
$icon-arrow-down: unicode(eb5d);
$icon-arrow-left: unicode(eb5e);
$icon-arrow-right: unicode(eb5f);
$icon-arrow-s: unicode(eb60);
$icon-arrow-w: unicode(eb61);
$icon-arrow-e: unicode(eb62);
$icon-arrow-n: unicode(eb63);
$icon-arrow-up: unicode(eb64);
$icon-tap-01: unicode(eb65);
$icon-tap-02: unicode(eb66);
$icon-target: unicode(eb67);
$icon-tea: unicode(eb68);
$icon-temperature-2: unicode(eb69);
$icon-event-ticket: unicode(eb6a);
$icon-tie-01: unicode(eb6b);
$icon-tie-02: unicode(eb6c);
$icon-layout: unicode(eb6d);
$icon-clock: unicode(eb6e);
$icon-time-alarm: unicode(eb6f);
$icon-time-clock: unicode(eb70);
$icon--stopwatch: unicode(eb71);
$icon-time: unicode(eb72);
$icon-todo: unicode(eb73);
$icon-tooth: unicode(eb74);
$icon-screen-touch: unicode(eb75);
$icon-track-delivery: unicode(eb76);
$icon-train: unicode(eb77);
$icon-transform: unicode(eb78);
$icon-trash-can: unicode(eb79);
$icon-tree: unicode(eb7a);
$icon-trend-down: unicode(eb7b);
$icon-trend-up: unicode(eb7c);
$icon-pointing-down: unicode(eb7d);
$icon-pointing-left: unicode(eb7e);
$icon-triangle-right: unicode(eb7f);
$icon-pointing-right: unicode(eb80);
$icon-pointing-up: unicode(eb81);
$icon-trolley: unicode(eb82);
$icon-trophy: unicode(eb83);
$icon-turtle: unicode(eb84);
$icon-tv: unicode(eb85);
$icon-undo-25: unicode(eb86);
$icon-undo-29: unicode(eb87);
$icon-ungroup: unicode(eb88);
$icon-unite: unicode(eb89);
$icon-upload-file: unicode(eb8a);
$icon-vector: unicode(eb8b);
$icon-film: unicode(eb8c);
$icon-virus: unicode(eb8d);
$icon-volume-2: unicode(eb8e);
$icon-sound: unicode(eb8f);
$icon-volume: unicode(eb90);
$icon-volume-mute: unicode(eb91);
$icon-volume-down: unicode(eb92);
$icon-volume-off: unicode(eb93);
$icon-volume-up: unicode(eb94);
$icon-vpn: unicode(eb95);
$icon-wallet-90: unicode(eb96);
$icon-wand-11: unicode(eb97);
$icon-waste-recycling: unicode(eb98);
$icon-waste: unicode(eb99);
$icon-watch: unicode(eb9a);
$icon-smartwatch: unicode(eb9b);
$icon-web-design: unicode(eb9c);
$icon-webcam: unicode(eb9d);
$icon-webpage: unicode(eb9e);
$icon-wheelchair-2: unicode(eb9f);
$icon-widget: unicode(eba0);
$icon-wifi-off: unicode(eba1);
$icon-wifi-protected: unicode(eba2);
$icon-wifi: unicode(eba3);
$icon-window-code: unicode(eba4);
$icon-window-dev: unicode(eba5);
$icon-window-paragraph: unicode(eba6);
$icon-woman-24: unicode(eba7);
$icon-world: unicode(eba8);
$icon-switches: unicode(eba9);
$icon-contact-86: unicode(ebaa);
$icon-contact-88: unicode(ebab);
$icon-zip-54: unicode(ebac);
$icon-zip-55: unicode(ebad);
$icon-text-size: unicode(ebae);
$icon-zoom-99: unicode(ebaf);
$icon-zoom-100: unicode(ebb0);
$icon-magnifier-zoom-in: unicode(ebb1);
$icon-magnifier-zoom-out: unicode(ebb2);
$icon-magnifier: unicode(ebb3);
$icon-zoom-in: unicode(ebb4);
$icon-zoom-out: unicode(ebb5);
$icon-search-zoom-in: unicode(ebb6);
$icon-search-zoom-out: unicode(ebb7);
$icon-search: unicode(ebb8);
$icon-expand: unicode(ebb9);
$icon-zoom: unicode(ebba);
$icon-pencil-47: unicode(ebbb);
$icon-dots: unicode(ebbc);
$icon-refresh-01: unicode(ebbd);
$icon-refresh-02: unicode(ebbe);
$icon-mirror-tablet-phone: unicode(ebbf);
$icon-full-screen: unicode(ebc0);
$icon-sidebar: unicode(ebc1);
$icon-blog: unicode(ebc2);
$icon-file-delete: unicode(ebc3);
$icon-file-add: unicode(ebc4);
$icon-fav-remove: unicode(ebc5);
$icon-gantt: unicode(ebc6);
$icon-alarm-add: unicode(ebc7);
$icon-timeline: unicode(ebc8);
$icon-comment-add: unicode(ebc9);
$icon-alarm-disable: unicode(ebca);
$icon-cycling: unicode(ebcb);
$icon-car-parking: unicode(ebcc);
$icon-seatbelt: unicode(ebcd);
$icon-battery-level: unicode(ebce);
$icon-engine-start: unicode(ebcf);
$icon-parking: unicode(ebd0);
$icon-cards: unicode(ebd1);
$icon-shape-triangle-2: unicode(ebd2);
$icon-shape-oval: unicode(ebd3);
$icon-shape-rectangle: unicode(ebd4);
$icon-grid-system: unicode(ebd5);
$icon-components: unicode(ebd6);
$icon-tool-select: unicode(ebd7);
$icon-zoom-e: unicode(ebd8);
$icon-expand-window: unicode(ebd9);
$icon-resize-v: unicode(ebda);
$icon-resize-h: unicode(ebdb);
$icon-shape-star: unicode(ebdc);
$icon-opening-times: unicode(ebdd);
$icon-bill: unicode(ebde);
$icon-event-create: unicode(ebdf);
$icon-event-confirm: unicode(ebe0);
$icon-recipe: unicode(ebe1);
$icon-recipe-create: unicode(ebe2);
$icon-bag-delivery: unicode(ebe3);
$icon-hot-key: unicode(ebe4);
$icon-img-stack: unicode(ebe5);
$icon-tags-stack: unicode(ebe6);
$icon-check-all: unicode(ebe7);
$icon-ranking: unicode(ebe8);
$icon-distance: unicode(ebe9);
$icon-server-rack: unicode(ebea);
$icon-users-wm: unicode(ebeb);
$icon-users-mm: unicode(ebec);
$icon-users-ww: unicode(ebed);
$icon-check-double: unicode(ebee);
$icon-check-single: unicode(ebef);
$icon-a-tag: unicode(ebf0);
$icon-questionnaire: unicode(ebf1);
$icon-check-list: unicode(ebf2);
$icon-survey: unicode(ebf3);
$icon-fav-list: unicode(ebf4);
$icon-save-to-list: unicode(ebf5);
$icon-star-rate: unicode(ebf6);
$icon-bookmarks: unicode(ebf7);
$icon-feedback: unicode(ebf8);
$icon-crossroad: unicode(ebf9);
$icon-blueprint: unicode(ebfa);
$icon-land: unicode(ebfb);
$icon-construction-sign: unicode(ebfc);
$icon-property-location: unicode(ebfd);
$icon-ai: unicode(ebfe);
$icon-machine-learning: unicode(ebff);
$icon-mobile-chat: unicode(ec00);
$icon-priority-lowest: unicode(ec01);
$icon-priority-low: unicode(ec02);
$icon-priority-normal: unicode(ec03);
$icon-priority-high: unicode(ec04);
$icon-priority-highest: unicode(ec05);
$icon-server: unicode(ec06);
$icon-translation: unicode(ec07);
$icon-three-dimensional-object: unicode(ec08);
$icon-privacy: unicode(ec09);
$icon-privacy-settings: unicode(ec0a);
$icon-browse: unicode(ec0b);
$icon-media-stream: unicode(ec0c);
$icon-podcast-mic: unicode(ec0d);
$icon-edit-brightness: unicode(ec0e);
$icon-soundwave: unicode(ec0f);
$icon-sticker: unicode(ec10);
$icon-fireworks: unicode(ec11);
$icon-storage-unit: unicode(ec12);
$icon-party: unicode(ec13);

.icon-norway::before {
  content: $icon-norway;
}

.icon-usa::before {
  content: $icon-usa;
}

.icon-denmark::before {
  content: $icon-denmark;
}

.icon-sweeden::before {
  content: $icon-sweeden;
}

.icon-active-38::before {
  content: $icon-active-38;
}

.icon-active-40::before {
  content: $icon-active-40;
}

.icon-c-warning::before {
  content: $icon-c-warning;
}

.icon-c-question::before {
  content: $icon-c-question;
}

.icon-c-info::before {
  content: $icon-c-info;
}

.icon-t-warning::before {
  content: $icon-t-warning;
}

.icon-align-center::before {
  content: $icon-align-center;
}

.icon-align-justify::before {
  content: $icon-align-justify;
}

.icon-align-left-2::before {
  content: $icon-align-left-2;
}

.icon-align-right-2::before {
  content: $icon-align-right-2;
}

.icon-s-pulse::before {
  content: $icon-s-pulse;
}

.icon-angle::before {
  content: $icon-angle;
}

.icon-apple::before {
  content: $icon-apple;
}

.icon-archive-drawer::before {
  content: $icon-archive-drawer;
}

.icon-atom::before {
  content: $icon-atom;
}

.icon-attachment::before {
  content: $icon-attachment;
}

.icon-attach::before {
  content: $icon-attach;
}

.icon-microphone-2::before {
  content: $icon-microphone-2;
}

.icon-award-49::before {
  content: $icon-award-49;
}

.icon-medal::before {
  content: $icon-medal;
}

.icon-award::before {
  content: $icon-award;
}

.icon-b-meeting::before {
  content: $icon-b-meeting;
}

.icon-badge-13::before {
  content: $icon-badge-13;
}

.icon-badge::before {
  content: $icon-badge;
}

.icon-shopping-bag::before {
  content: $icon-shopping-bag;
}

.icon-bag-20::before {
  content: $icon-bag-20;
}

.icon-bag::before {
  content: $icon-bag;
}

.icon-ban::before {
  content: $icon-ban;
}

.icon-temple::before {
  content: $icon-temple;
}

.icon-barcode-qr::before {
  content: $icon-barcode-qr;
}

.icon-bluetooth::before {
  content: $icon-bluetooth;
}

.icon-books::before {
  content: $icon-books;
}

.icon-box-2::before {
  content: $icon-box-2;
}

.icon-box-ribbon::before {
  content: $icon-box-ribbon;
}

.icon-box::before {
  content: $icon-box;
}

.icon-brain::before {
  content: $icon-brain;
}

.icon-sun::before {
  content: $icon-sun;
}

.icon-bug::before {
  content: $icon-bug;
}

.icon-office::before {
  content: $icon-office;
}

.icon-bulb-63::before {
  content: $icon-bulb-63;
}

.icon-bulb-saver::before {
  content: $icon-bulb-saver;
}

.icon-cable::before {
  content: $icon-cable;
}

.icon-camera::before {
  content: $icon-camera;
}

.icon-canvas::before {
  content: $icon-canvas;
}

.icon-car-front::before {
  content: $icon-car-front;
}

.icon-chart-bar-33::before {
  content: $icon-chart-bar-33;
}

.icon-b-chat::before {
  content: $icon-b-chat;
}

.icon-check::before {
  content: $icon-check;
}

.icon-d-check::before {
  content: $icon-d-check;
}

.icon-cheque::before {
  content: $icon-cheque;
}

.icon-circle-10::before {
  content: $icon-circle-10;
}

.icon-clone::before {
  content: $icon-clone;
}

.icon-cloud-forecast::before {
  content: $icon-cloud-forecast;
}

.icon-cloud-download-93::before {
  content: $icon-cloud-download-93;
}

.icon-migration::before {
  content: $icon-migration;
}

.icon-code::before {
  content: $icon-code;
}

.icon-coins::before {
  content: $icon-coins;
}

.icon-command::before {
  content: $icon-command;
}

.icon-compare::before {
  content: $icon-compare;
}

.icon-compass-06::before {
  content: $icon-compass-06;
}

.icon-computer::before {
  content: $icon-computer;
}

.icon-cone::before {
  content: $icon-cone;
}

.icon-connection::before {
  content: $icon-connection;
}

.icon-conversion::before {
  content: $icon-conversion;
}

.icon-copy::before {
  content: $icon-copy;
}

.icon-countdown-2::before {
  content: $icon-countdown-2;
}

.icon-coupon::before {
  content: $icon-coupon;
}

.icon-credit-card-in::before {
  content: $icon-credit-card-in;
}

.icon-credit-card::before {
  content: $icon-credit-card;
}

.icon-crop::before {
  content: $icon-crop;
}

.icon-crosshair::before {
  content: $icon-crosshair;
}

.icon-currency-exchange::before {
  content: $icon-currency-exchange;
}

.icon-cursor-pointer::before {
  content: $icon-cursor-pointer;
}

.icon-respond-arrow::before {
  content: $icon-respond-arrow;
}

.icon-reply-arrow::before {
  content: $icon-reply-arrow;
}

.icon-customer-support::before {
  content: $icon-customer-support;
}

.icon-f-dashboard::before {
  content: $icon-f-dashboard;
}

.icon-database::before {
  content: $icon-database;
}

.icon-delete-x::before {
  content: $icon-delete-x;
}

.icon-delete-key::before {
  content: $icon-delete-key;
}

.icon-delivery-3::before {
  content: $icon-delivery-3;
}

.icon-delivery-fast::before {
  content: $icon-delivery-fast;
}

.icon-delivery::before {
  content: $icon-delivery;
}

.icon-design::before {
  content: $icon-design;
}

.icon-wheelchair::before {
  content: $icon-wheelchair;
}

.icon-discount-2::before {
  content: $icon-discount-2;
}

.icon-dna-27::before {
  content: $icon-dna-27;
}

.icon-doctor::before {
  content: $icon-doctor;
}

.icon-i-edit::before {
  content: $icon-i-edit;
}

.icon-edit::before {
  content: $icon-edit;
}

.icon-eraser-33::before {
  content: $icon-eraser-33;
}

.icon-exchange::before {
  content: $icon-exchange;
}

.icon-explore-2::before {
  content: $icon-explore-2;
}

.icon-eye::before {
  content: $icon-eye;
}

.icon-b-eye::before {
  content: $icon-b-eye;
}

.icon-heart::before {
  content: $icon-heart;
}

.icon-star::before {
  content: $icon-star;
}

.icon-file-download-89::before {
  content: $icon-file-download-89;
}

.icon-file-upload-88::before {
  content: $icon-file-upload-88;
}

.icon-filter-check::before {
  content: $icon-filter-check;
}

.icon-filter-remove::before {
  content: $icon-filter-remove;
}

.icon-filter::before {
  content: $icon-filter;
}

.icon-flag::before {
  content: $icon-flag;
}

.icon-folder-19::before {
  content: $icon-folder-19;
}

.icon-gift::before {
  content: $icon-gift;
}

.icon-archery-target::before {
  content: $icon-archery-target;
}

.icon-gps::before {
  content: $icon-gps;
}

.icon-grammar-check::before {
  content: $icon-grammar-check;
}

.icon-grid-interface::before {
  content: $icon-grid-interface;
}

.icon-hand-card::before {
  content: $icon-hand-card;
}

.icon-handout::before {
  content: $icon-handout;
}

.icon-hat-3::before {
  content: $icon-hat-3;
}

.icon-headphones-mic::before {
  content: $icon-headphones-mic;
}

.icon-headphones::before {
  content: $icon-headphones;
}

.icon-favorite::before {
  content: $icon-favorite;
}

.icon-heartbeat::before {
  content: $icon-heartbeat;
}

.icon-hierarchy-53::before {
  content: $icon-hierarchy-53;
}

.icon-home::before {
  content: $icon-home;
}

.icon-hospital-32::before {
  content: $icon-hospital-32;
}

.icon-hotel::before {
  content: $icon-hotel;
}

.icon-infinite::before {
  content: $icon-infinite;
}

.icon-input::before {
  content: $icon-input;
}

.icon-key::before {
  content: $icon-key;
}

.icon-keyboard-hide::before {
  content: $icon-keyboard-hide;
}

.icon-keyboard::before {
  content: $icon-keyboard;
}

.icon-lab::before {
  content: $icon-lab;
}

.icon-language::before {
  content: $icon-language;
}

.icon-launch::before {
  content: $icon-launch;
}

.icon-law::before {
  content: $icon-law;
}

.icon-layers-2::before {
  content: $icon-layers-2;
}

.icon-stack::before {
  content: $icon-stack;
}

.icon-leaf::before {
  content: $icon-leaf;
}

.icon-light-traffic::before {
  content: $icon-light-traffic;
}

.icon-thumb-down::before {
  content: $icon-thumb-down;
}

.icon-thumb-up::before {
  content: $icon-thumb-up;
}

.icon-url::before {
  content: $icon-url;
}

.icon-unlink::before {
  content: $icon-unlink;
}

.icon-list-bullet::before {
  content: $icon-list-bullet;
}

.icon-list-numbers::before {
  content: $icon-list-numbers;
}

.icon-unlocked::before {
  content: $icon-unlocked;
}

.icon-lock::before {
  content: $icon-lock;
}

.icon-log-in::before {
  content: $icon-log-in;
}

.icon-log-out::before {
  content: $icon-log-out;
}

.icon-loop-82::before {
  content: $icon-loop-82;
}

.icon-loop-83::before {
  content: $icon-loop-83;
}

.icon-magnet::before {
  content: $icon-magnet;
}

.icon-map::before {
  content: $icon-map;
}

.icon-marker::before {
  content: $icon-marker;
}

.icon-math::before {
  content: $icon-math;
}

.icon-measure-02::before {
  content: $icon-measure-02;
}

.icon-measure-17::before {
  content: $icon-measure-17;
}

.icon-measure-big::before {
  content: $icon-measure-big;
}

.icon-meeting::before {
  content: $icon-meeting;
}

.icon-menu-8::before {
  content: $icon-menu-8;
}

.icon-menu-7::before {
  content: $icon-menu-7;
}

.icon-menu-6::before {
  content: $icon-menu-6;
}

.icon-menu-5::before {
  content: $icon-menu-5;
}

.icon-menu-4::before {
  content: $icon-menu-4;
}

.icon-menu-3::before {
  content: $icon-menu-3;
}

.icon-menu::before {
  content: $icon-menu;
}

.icon-merge-2::before {
  content: $icon-merge-2;
}

.icon-merge::before {
  content: $icon-merge;
}

.icon-metrics::before {
  content: $icon-metrics;
}

.icon-mic-2::before {
  content: $icon-mic-2;
}

.icon-microscope::before {
  content: $icon-microscope;
}

.icon-down-arrow::before {
  content: $icon-down-arrow;
}

.icon-left-arrow::before {
  content: $icon-left-arrow;
}

.icon-right-arrow::before {
  content: $icon-right-arrow;
}

.icon-up-arrow::before {
  content: $icon-up-arrow;
}

.icon-phone-charging::before {
  content: $icon-phone-charging;
}

.icon-money-coins::before {
  content: $icon-money-coins;
}

.icon-money-growth::before {
  content: $icon-money-growth;
}

.icon-money-time::before {
  content: $icon-money-time;
}

.icon-pc-mouse::before {
  content: $icon-pc-mouse;
}

.icon-move-05::before {
  content: $icon-move-05;
}

.icon-move-92::before {
  content: $icon-move-92;
}

.icon-move-down::before {
  content: $icon-move-down;
}

.icon-move-left::before {
  content: $icon-move-left;
}

.icon-move-right::before {
  content: $icon-move-right;
}

.icon-move-up::before {
  content: $icon-move-up;
}

.icon-multiple-11::before {
  content: $icon-multiple-11;
}

.icon-multiple::before {
  content: $icon-multiple;
}

.icon-network::before {
  content: $icon-network;
}

.icon-newsletter::before {
  content: $icon-newsletter;
}

.icon-night::before {
  content: $icon-night;
}

.icon-music::before {
  content: $icon-music;
}

.icon-notepad::before {
  content: $icon-notepad;
}

.icon-paint-16::before {
  content: $icon-paint-16;
}

.icon-palette::before {
  content: $icon-palette;
}

.icon-panel::before {
  content: $icon-panel;
}

.icon-pantone::before {
  content: $icon-pantone;
}

.icon-paper-diploma::before {
  content: $icon-paper-diploma;
}

.icon-paper::before {
  content: $icon-paper;
}

.icon-paragraph::before {
  content: $icon-paragraph;
}

.icon-parent::before {
  content: $icon-parent;
}

.icon-patch-19::before {
  content: $icon-patch-19;
}

.icon-patch-34::before {
  content: $icon-patch-34;
}

.icon-patch::before {
  content: $icon-patch;
}

.icon-path-exclude::before {
  content: $icon-path-exclude;
}

.icon-path-intersect::before {
  content: $icon-path-intersect;
}

.icon-path-minus::before {
  content: $icon-path-minus;
}

.icon-path-unite::before {
  content: $icon-path-unite;
}

.icon-paw::before {
  content: $icon-paw;
}

.icon-payment::before {
  content: $icon-payment;
}

.icon-computer-monitor::before {
  content: $icon-computer-monitor;
}

.icon-pen-tool::before {
  content: $icon-pen-tool;
}

.icon-pencil::before {
  content: $icon-pencil;
}

.icon-percentage-38::before {
  content: $icon-percentage-38;
}

.icon-phone::before {
  content: $icon-phone;
}

.icon-phone-call-end::before {
  content: $icon-phone-call-end;
}

.icon-phone-call::before {
  content: $icon-phone-call;
}

.icon-mobile-phone::before {
  content: $icon-mobile-phone;
}

.icon-pig::before {
  content: $icon-pig;
}

.icon-pill-container-44::before {
  content: $icon-pill-container-44;
}

.icon-pen-23::before {
  content: $icon-pen-23;
}

.icon-pen-01::before {
  content: $icon-pen-01;
}

.icon-pin::before {
  content: $icon-pin;
}

.icon-plane-18::before {
  content: $icon-plane-18;
}

.icon-plant-ground::before {
  content: $icon-plant-ground;
}

.icon-plant-vase::before {
  content: $icon-plant-vase;
}

.icon-play-movie::before {
  content: $icon-play-movie;
}

.icon-media-player::before {
  content: $icon-media-player;
}

.icon-plug::before {
  content: $icon-plug;
}

.icon-podium-trophy::before {
  content: $icon-podium-trophy;
}

.icon-point-a::before {
  content: $icon-point-a;
}

.icon-point-b::before {
  content: $icon-point-b;
}

.icon-pos::before {
  content: $icon-pos;
}

.icon-position-pin::before {
  content: $icon-position-pin;
}

.icon-system-preferences::before {
  content: $icon-system-preferences;
}

.icon-setup-preferences::before {
  content: $icon-setup-preferences;
}

.icon-options::before {
  content: $icon-options;
}

.icon-preferences::before {
  content: $icon-preferences;
}

.icon-printer::before {
  content: $icon-printer;
}

.icon-progress::before {
  content: $icon-progress;
}

.icon-pulse-sleep::before {
  content: $icon-pulse-sleep;
}

.icon-watch-heartbeat::before {
  content: $icon-watch-heartbeat;
}

.icon-pulse::before {
  content: $icon-pulse;
}

.icon-puzzle-10::before {
  content: $icon-puzzle-10;
}

.icon-quite-happy::before {
  content: $icon-quite-happy;
}

.icon-quote::before {
  content: $icon-quote;
}

.icon-radio::before {
  content: $icon-radio;
}

.icon-receipt-list-42::before {
  content: $icon-receipt-list-42;
}

.icon-receipt-list-43::before {
  content: $icon-receipt-list-43;
}

.icon-recycling::before {
  content: $icon-recycling;
}

.icon-redo-26::before {
  content: $icon-redo-26;
}

.icon-refresh::before {
  content: $icon-refresh;
}

.icon-reload::before {
  content: $icon-reload;
}

.icon-replay::before {
  content: $icon-replay;
}

.icon-restore::before {
  content: $icon-restore;
}

.icon-road-2::before {
  content: $icon-road-2;
}

.icon-screen-rotation::before {
  content: $icon-screen-rotation;
}

.icon-round-dollar::before {
  content: $icon-round-dollar;
}

.icon-route-alert::before {
  content: $icon-route-alert;
}

.icon-route-close::before {
  content: $icon-route-close;
}

.icon-route-open::before {
  content: $icon-route-open;
}

.icon-ruler-pencil::before {
  content: $icon-ruler-pencil;
}

.icon-safe::before {
  content: $icon-safe;
}

.icon-scale::before {
  content: $icon-scale;
}

.icon-scan::before {
  content: $icon-scan;
}

.icon-scissors-dashed::before {
  content: $icon-scissors-dashed;
}

.icon-scissors::before {
  content: $icon-scissors;
}

.icon-search-content::before {
  content: $icon-search-content;
}

.icon-search-file::before {
  content: $icon-search-file;
}

.icon-security::before {
  content: $icon-security;
}

.icon-segmentation::before {
  content: $icon-segmentation;
}

.icon-increase::before {
  content: $icon-increase;
}

.icon-enlarge::before {
  content: $icon-enlarge;
}

.icon-send::before {
  content: $icon-send;
}

.icon-separate::before {
  content: $icon-separate;
}

.icon-settings-46::before {
  content: $icon-settings-46;
}

.icon-settings-99::before {
  content: $icon-settings-99;
}

.icon-cogwheel::before {
  content: $icon-cogwheel;
}

.icon-settings-gear::before {
  content: $icon-settings-gear;
}

.icon-gear::before {
  content: $icon-gear;
}

.icon-settings::before {
  content: $icon-settings;
}

.icon-network-communication::before {
  content: $icon-network-communication;
}

.icon-upload::before {
  content: $icon-upload;
}

.icon-social-sharing::before {
  content: $icon-social-sharing;
}

.icon-sharing::before {
  content: $icon-sharing;
}

.icon-shared::before {
  content: $icon-shared;
}

.icon-reply::before {
  content: $icon-reply;
}

.icon-shuffle-97::before {
  content: $icon-shuffle-97;
}

.icon-sign::before {
  content: $icon-sign;
}

.icon-signature::before {
  content: $icon-signature;
}

.icon-e-add::before {
  content: $icon-e-add;
}

.icon-e-delete::before {
  content: $icon-e-delete;
}

.icon-pins::before {
  content: $icon-pins;
}

.icon-e-remove::before {
  content: $icon-e-remove;
}

.icon-single-05::before {
  content: $icon-single-05;
}

.icon-single-content-02::before {
  content: $icon-single-content-02;
}

.icon-single-content-03::before {
  content: $icon-single-content-03;
}

.icon-single-copies::before {
  content: $icon-single-copies;
}

.icon-single-copy-04::before {
  content: $icon-single-copy-04;
}

.icon-single-copy-06::before {
  content: $icon-single-copy-06;
}

.icon-file-content::before {
  content: $icon-file-content;
}

.icon-single-folded::before {
  content: $icon-single-folded;
}

.icon-file-paragraph::before {
  content: $icon-file-paragraph;
}

.icon-single-position::before {
  content: $icon-single-position;
}

.icon-single::before {
  content: $icon-single;
}

.icon-slice::before {
  content: $icon-slice;
}

.icon-slide-left::before {
  content: $icon-slide-left;
}

.icon-slide-right::before {
  content: $icon-slide-right;
}

.icon-i-add::before {
  content: $icon-i-add;
}

.icon-i-delete::before {
  content: $icon-i-delete;
}

.icon-small-down::before {
  content: $icon-small-down;
}

.icon-small-left::before {
  content: $icon-small-left;
}

.icon-i-remove::before {
  content: $icon-i-remove;
}

.icon-small-right::before {
  content: $icon-small-right;
}

.icon-small-triangle-down::before {
  content: $icon-small-triangle-down;
}

.icon-small-triangle-left::before {
  content: $icon-small-triangle-left;
}

.icon-small-triangle-right::before {
  content: $icon-small-triangle-right;
}

.icon-small-triangle-up::before {
  content: $icon-small-triangle-up;
}

.icon-small-up::before {
  content: $icon-small-up;
}

.icon-snow::before {
  content: $icon-snow;
}

.icon-sound-wave::before {
  content: $icon-sound-wave;
}

.icon-loudspeaker::before {
  content: $icon-loudspeaker;
}

.icon-split::before {
  content: $icon-split;
}

.icon-data-download::before {
  content: $icon-data-download;
}

.icon-square-pin::before {
  content: $icon-square-pin;
}

.icon-s-remove::before {
  content: $icon-s-remove;
}

.icon-frame-s-caret-right::before {
  content: $icon-frame-s-caret-right;
}

.icon-square-simple-down::before {
  content: $icon-square-simple-down;
}

.icon-square-simple-left::before {
  content: $icon-square-simple-left;
}

.icon-square-simple-right::before {
  content: $icon-square-simple-right;
}

.icon-square-simple-up::before {
  content: $icon-square-simple-up;
}

.icon-data-upload::before {
  content: $icon-data-upload;
}

.icon-stamp::before {
  content: $icon-stamp;
}

.icon-standing-man::before {
  content: $icon-standing-man;
}

.icon-standing-woman::before {
  content: $icon-standing-woman;
}

.icon-steps::before {
  content: $icon-steps;
}

.icon-stock-2::before {
  content: $icon-stock-2;
}

.icon-stock::before {
  content: $icon-stock;
}

.icon-stretch::before {
  content: $icon-stretch;
}

.icon-food-supplement::before {
  content: $icon-food-supplement;
}

.icon-lifering::before {
  content: $icon-lifering;
}

.icon-support::before {
  content: $icon-support;
}

.icon-swap-horizontal::before {
  content: $icon-swap-horizontal;
}

.icon-swap-vertical::before {
  content: $icon-swap-vertical;
}

.icon-sync-devices::before {
  content: $icon-sync-devices;
}

.icon-syringe::before {
  content: $icon-syringe;
}

.icon-system-update::before {
  content: $icon-system-update;
}

.icon-tactic::before {
  content: $icon-tactic;
}

.icon-tag::before {
  content: $icon-tag;
}

.icon-arrow-down::before {
  content: $icon-arrow-down;
}

.icon-arrow-left::before {
  content: $icon-arrow-left;
}

.icon-arrow-right::before {
  content: $icon-arrow-right;
}

.icon-arrow-s::before {
  content: $icon-arrow-s;
}

.icon-arrow-w::before {
  content: $icon-arrow-w;
}

.icon-arrow-e::before {
  content: $icon-arrow-e;
}

.icon-arrow-n::before {
  content: $icon-arrow-n;
}

.icon-arrow-up::before {
  content: $icon-arrow-up;
}

.icon-tap-01::before {
  content: $icon-tap-01;
}

.icon-tap-02::before {
  content: $icon-tap-02;
}

.icon-target::before {
  content: $icon-target;
}

.icon-tea::before {
  content: $icon-tea;
}

.icon-temperature-2::before {
  content: $icon-temperature-2;
}

.icon-event-ticket::before {
  content: $icon-event-ticket;
}

.icon-tie-01::before {
  content: $icon-tie-01;
}

.icon-tie-02::before {
  content: $icon-tie-02;
}

.icon-layout::before {
  content: $icon-layout;
}

.icon-clock::before {
  content: $icon-clock;
}

.icon-time-alarm::before {
  content: $icon-time-alarm;
}

.icon-time-clock::before {
  content: $icon-time-clock;
}

.icon--stopwatch::before {
  content: $icon--stopwatch;
}

.icon-time::before {
  content: $icon-time;
}

.icon-todo::before {
  content: $icon-todo;
}

.icon-tooth::before {
  content: $icon-tooth;
}

.icon-screen-touch::before {
  content: $icon-screen-touch;
}

.icon-track-delivery::before {
  content: $icon-track-delivery;
}

.icon-train::before {
  content: $icon-train;
}

.icon-transform::before {
  content: $icon-transform;
}

.icon-trash-can::before {
  content: $icon-trash-can;
}

.icon-tree::before {
  content: $icon-tree;
}

.icon-trend-down::before {
  content: $icon-trend-down;
}

.icon-trend-up::before {
  content: $icon-trend-up;
}

.icon-pointing-down::before {
  content: $icon-pointing-down;
}

.icon-pointing-left::before {
  content: $icon-pointing-left;
}

.icon-triangle-right::before {
  content: $icon-triangle-right;
}

.icon-pointing-right::before {
  content: $icon-pointing-right;
}

.icon-pointing-up::before {
  content: $icon-pointing-up;
}

.icon-trolley::before {
  content: $icon-trolley;
}

.icon-trophy::before {
  content: $icon-trophy;
}

.icon-turtle::before {
  content: $icon-turtle;
}

.icon-tv::before {
  content: $icon-tv;
}

.icon-undo-25::before {
  content: $icon-undo-25;
}

.icon-undo-29::before {
  content: $icon-undo-29;
}

.icon-ungroup::before {
  content: $icon-ungroup;
}

.icon-unite::before {
  content: $icon-unite;
}

.icon-upload-file::before {
  content: $icon-upload-file;
}

.icon-vector::before {
  content: $icon-vector;
}

.icon-film::before {
  content: $icon-film;
}

.icon-virus::before {
  content: $icon-virus;
}

.icon-volume-2::before {
  content: $icon-volume-2;
}

.icon-sound::before {
  content: $icon-sound;
}

.icon-volume::before {
  content: $icon-volume;
}

.icon-volume-mute::before {
  content: $icon-volume-mute;
}

.icon-volume-down::before {
  content: $icon-volume-down;
}

.icon-volume-off::before {
  content: $icon-volume-off;
}

.icon-volume-up::before {
  content: $icon-volume-up;
}

.icon-vpn::before {
  content: $icon-vpn;
}

.icon-wallet-90::before {
  content: $icon-wallet-90;
}

.icon-wand-11::before {
  content: $icon-wand-11;
}

.icon-waste-recycling::before {
  content: $icon-waste-recycling;
}

.icon-waste::before {
  content: $icon-waste;
}

.icon-watch::before {
  content: $icon-watch;
}

.icon-smartwatch::before {
  content: $icon-smartwatch;
}

.icon-web-design::before {
  content: $icon-web-design;
}

.icon-webcam::before {
  content: $icon-webcam;
}

.icon-webpage::before {
  content: $icon-webpage;
}

.icon-wheelchair-2::before {
  content: $icon-wheelchair-2;
}

.icon-widget::before {
  content: $icon-widget;
}

.icon-wifi-off::before {
  content: $icon-wifi-off;
}

.icon-wifi-protected::before {
  content: $icon-wifi-protected;
}

.icon-wifi::before {
  content: $icon-wifi;
}

.icon-window-code::before {
  content: $icon-window-code;
}

.icon-window-dev::before {
  content: $icon-window-dev;
}

.icon-window-paragraph::before {
  content: $icon-window-paragraph;
}

.icon-woman-24::before {
  content: $icon-woman-24;
}

.icon-world::before {
  content: $icon-world;
}

.icon-switches::before {
  content: $icon-switches;
}

.icon-contact-86::before {
  content: $icon-contact-86;
}

.icon-contact-88::before {
  content: $icon-contact-88;
}

.icon-zip-54::before {
  content: $icon-zip-54;
}

.icon-zip-55::before {
  content: $icon-zip-55;
}

.icon-text-size::before {
  content: $icon-text-size;
}

.icon-zoom-99::before {
  content: $icon-zoom-99;
}

.icon-zoom-100::before {
  content: $icon-zoom-100;
}

.icon-magnifier-zoom-in::before {
  content: $icon-magnifier-zoom-in;
}

.icon-magnifier-zoom-out::before {
  content: $icon-magnifier-zoom-out;
}

.icon-magnifier::before {
  content: $icon-magnifier;
}

.icon-zoom-in::before {
  content: $icon-zoom-in;
}

.icon-zoom-out::before {
  content: $icon-zoom-out;
}

.icon-search-zoom-in::before {
  content: $icon-search-zoom-in;
}

.icon-search-zoom-out::before {
  content: $icon-search-zoom-out;
}

.icon-search::before {
  content: $icon-search;
}

.icon-expand::before {
  content: $icon-expand;
}

.icon-zoom::before {
  content: $icon-zoom;
}

.icon-pencil-47::before {
  content: $icon-pencil-47;
}

.icon-dots::before {
  content: $icon-dots;
}

.icon-refresh-01::before {
  content: $icon-refresh-01;
}

.icon-refresh-02::before {
  content: $icon-refresh-02;
}

.icon-mirror-tablet-phone::before {
  content: $icon-mirror-tablet-phone;
}

.icon-full-screen::before {
  content: $icon-full-screen;
}

.icon-sidebar::before {
  content: $icon-sidebar;
}

.icon-blog::before {
  content: $icon-blog;
}

.icon-file-delete::before {
  content: $icon-file-delete;
}

.icon-file-add::before {
  content: $icon-file-add;
}

.icon-fav-remove::before {
  content: $icon-fav-remove;
}

.icon-gantt::before {
  content: $icon-gantt;
}

.icon-alarm-add::before {
  content: $icon-alarm-add;
}

.icon-timeline::before {
  content: $icon-timeline;
}

.icon-comment-add::before {
  content: $icon-comment-add;
}

.icon-alarm-disable::before {
  content: $icon-alarm-disable;
}

.icon-cycling::before {
  content: $icon-cycling;
}

.icon-car-parking::before {
  content: $icon-car-parking;
}

.icon-seatbelt::before {
  content: $icon-seatbelt;
}

.icon-battery-level::before {
  content: $icon-battery-level;
}

.icon-engine-start::before {
  content: $icon-engine-start;
}

.icon-parking::before {
  content: $icon-parking;
}

.icon-cards::before {
  content: $icon-cards;
}

.icon-shape-triangle-2::before {
  content: $icon-shape-triangle-2;
}

.icon-shape-oval::before {
  content: $icon-shape-oval;
}

.icon-shape-rectangle::before {
  content: $icon-shape-rectangle;
}

.icon-grid-system::before {
  content: $icon-grid-system;
}

.icon-components::before {
  content: $icon-components;
}

.icon-tool-select::before {
  content: $icon-tool-select;
}

.icon-zoom-e::before {
  content: $icon-zoom-e;
}

.icon-expand-window::before {
  content: $icon-expand-window;
}

.icon-resize-v::before {
  content: $icon-resize-v;
}

.icon-resize-h::before {
  content: $icon-resize-h;
}

.icon-shape-star::before {
  content: $icon-shape-star;
}

.icon-opening-times::before {
  content: $icon-opening-times;
}

.icon-bill::before {
  content: $icon-bill;
}

.icon-event-create::before {
  content: $icon-event-create;
}

.icon-event-confirm::before {
  content: $icon-event-confirm;
}

.icon-recipe::before {
  content: $icon-recipe;
}

.icon-recipe-create::before {
  content: $icon-recipe-create;
}

.icon-bag-delivery::before {
  content: $icon-bag-delivery;
}

.icon-hot-key::before {
  content: $icon-hot-key;
}

.icon-img-stack::before {
  content: $icon-img-stack;
}

.icon-tags-stack::before {
  content: $icon-tags-stack;
}

.icon-check-all::before {
  content: $icon-check-all;
}

.icon-ranking::before {
  content: $icon-ranking;
}

.icon-distance::before {
  content: $icon-distance;
}

.icon-server-rack::before {
  content: $icon-server-rack;
}

.icon-users-wm::before {
  content: $icon-users-wm;
}

.icon-users-mm::before {
  content: $icon-users-mm;
}

.icon-users-ww::before {
  content: $icon-users-ww;
}

.icon-check-double::before {
  content: $icon-check-double;
}

.icon-check-single::before {
  content: $icon-check-single;
}

.icon-a-tag::before {
  content: $icon-a-tag;
}

.icon-questionnaire::before {
  content: $icon-questionnaire;
}

.icon-check-list::before {
  content: $icon-check-list;
}

.icon-survey::before {
  content: $icon-survey;
}

.icon-fav-list::before {
  content: $icon-fav-list;
}

.icon-save-to-list::before {
  content: $icon-save-to-list;
}

.icon-star-rate::before {
  content: $icon-star-rate;
}

.icon-bookmarks::before {
  content: $icon-bookmarks;
}

.icon-feedback::before {
  content: $icon-feedback;
}

.icon-crossroad::before {
  content: $icon-crossroad;
}

.icon-blueprint::before {
  content: $icon-blueprint;
}

.icon-land::before {
  content: $icon-land;
}

.icon-construction-sign::before {
  content: $icon-construction-sign;
}

.icon-property-location::before {
  content: $icon-property-location;
}

.icon-ai::before {
  content: $icon-ai;
}

.icon-machine-learning::before {
  content: $icon-machine-learning;
}

.icon-mobile-chat::before {
  content: $icon-mobile-chat;
}

.icon-priority-lowest::before {
  content: $icon-priority-lowest;
}

.icon-priority-low::before {
  content: $icon-priority-low;
}

.icon-priority-normal::before {
  content: $icon-priority-normal;
}

.icon-priority-high::before {
  content: $icon-priority-high;
}

.icon-priority-highest::before {
  content: $icon-priority-highest;
}

.icon-server::before {
  content: $icon-server;
}

.icon-translation::before {
  content: $icon-translation;
}

.icon-three-dimensional-object::before {
  content: $icon-three-dimensional-object;
}

.icon-privacy::before {
  content: $icon-privacy;
}

.icon-privacy-settings::before {
  content: $icon-privacy-settings;
}

.icon-browse::before {
  content: $icon-browse;
}

.icon-media-stream::before {
  content: $icon-media-stream;
}

.icon-podcast-mic::before {
  content: $icon-podcast-mic;
}

.icon-edit-brightness::before {
  content: $icon-edit-brightness;
}

.icon-soundwave::before {
  content: $icon-soundwave;
}

.icon-sticker::before {
  content: $icon-sticker;
}

.icon-fireworks::before {
  content: $icon-fireworks;
}

.icon-storage-unit::before {
  content: $icon-storage-unit;
}

.icon-party::before {
  content: $icon-party;
}
